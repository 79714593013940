html, body {
    height: 100%;
}

#root {
    height: 100%;
}

.App {
  background: linear-gradient(135deg, #3a3e63, #2e324d 20%, transparent 20.01%),
            linear-gradient(135deg, transparent, transparent 60%, #191e32 60.01%, #15182b),
            linear-gradient(90deg, #191e32, #34385b);
  background-color: black;
  height: 100%;
  color: white;
  text-align: center;
  overflow: auto;
}

.Menu {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #3a3e63, #2e324d 20%, transparent 20.01%),
            linear-gradient(135deg, transparent, transparent 60%, #191e32 60.01%, #15182b),
            linear-gradient(90deg, #191e32, #34385b);
}

.Menu .wrapper {
    position: relative; 
}

.Menu .wrapper .buttons {
    position: absolute;
    transform: translateY(30%);
    margin: 0;
}

.Menu .btn {
    display: block;
    margin-left: 3vh;
    margin-right: 3vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
    min-width: 60vw;
}

.style-colored {
    background-image: linear-gradient(90deg, #5474fc, #9d6bfb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.SyncBtn {
    border: none;
    border-radius: 3px;
    outline: none;
    max-width: 10vw;
    margin: 0.3vw;
    color: white;
    background-image: linear-gradient(90deg, #5474fc, #9d6bfb);
    padding: 0.15vh 0.3vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.5vw;
    font-weight: 600;
    text-transform: uppercase;
}

.btn {
    border: none;
    border-radius: 3px;
    outline: none;
    min-width: 40vw;
    margin: 1vw;
    color: white;
    background-image: linear-gradient(90deg, #5474fc, #9d6bfb);
    padding: 1.5vh 3vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 5vw;
    font-weight: 600;
    text-transform: uppercase;
}

.menu-button-active {
    color: white;
}

.btn.btn-large {
    min-width: 80vw;
}

.style-2-bottom-buttons {
    margin-top: 50vh;
}

.style-3-bottom-buttons {
    margin-top: 40vh;
}

.style-input-area {
    margin-top: 2vh;
}

.style-button-area {
    margin-top: 5vh;
}

@media (width: 375px) and (height: 812px){
    .btn {
        min-width: 150px;
    }

    .style-2-bottom-buttons {
        margin-top: 406px;
    }

    .style-3-bottom-buttons {
        margin-top: 325px;
    }

    .style-input-area {
        margin-top: 16px;
    }

    .stlye-button-area {
        margin-top: 41px;
    }

}

@media (width: 414px) and (height: 736px){
    .btn {
        min-width: 166px;
    }

    .style-2-bottom-buttons {
        margin-top: 368px;
    }

    .style-3-bottom-buttons {
        margin-top: 294px;
    }

    .style-input-area {
        margin-top: 15px;
    }

    .stlye-button-area {
        margin-top: 37px;
    }

}

@media (width: 375px) and (height: 667px){
    .btn {
        min-width: 188px;
    }

    .style-2-bottom-buttons {
        margin-top: 334px;
    }

    .style-3-bottom-buttons {
        margin-top: 267px;
    }

    .style-input-area {
        margin-top: 13px;
    }

    .stlye-button-area {
        margin-top: 33px;
    }
}
