.style-header {
    padding-top: 5vh;
    padding-bottom: 5vh;
    line-height: 3vh;
    font-size: 3vh;
    width: 100vw;
    text-align: center;
}

.style-header-left {
    margin-left: 3vh;
    float: left;
    color: #191e32;
}

.style-header-center {
    display: inline;
    font-weight: 600;
    text-transform: uppercase;
}

.style-header-right {
    margin-right: 3vh;
    float: right;
    color: #191e32;
}

