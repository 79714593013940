.style-player-area {
    margin-top: 2vh;
    margin-left: 9vw;
    margin-right: 9vw;
    width: 82vw;
    text-align: center;
}

.style-player-btn {
    position: relative;
    top: 0.1vh;
    font-weight: 700;
    font-size: 5vh;
}
