.style-input {
    position: relative;
    display: inline-block;
    margin-left: 1vw;
    width: 40vw;
}

.style-input.style-input-larger {
    width: 81vw;
}
.style-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.style-input input[type=number] {
  -moz-appearance: textfield;
}

.style-input input {
    outline: none;
    position: absolute;
    display: inline-block;
    text-align: center;
    top: 2.2vh;
    left: 0;
    border-radius: 0;
    border: 0;
    color: white;
    line-height: 4vh;
    font-size: 4vh;
    padding: 1px;
    padding-top: 0.8vh;
    padding-bottom: 0.7vh;
    z-index: 1;
    background-color: transparent;
    width: 40vw;
}

.style-input.style-input-larger input {
    width: 81vw;
}

.style-input .style-input-background {
    border-radius: 3px;
    line-height: 3vh;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 2vh;
    left: 0;
    width: 40vw;
}

.style-input.style-input-larger .style-input-background {
    width: 81vw;
}

.style-input.style-input-active .style-input-background {
    text-align: left;
}

.style-input.style-input-active .style-input-label {
    font-size: 1.5vh;
    position: relative;
    top: -4vh;
    left: 0.1vh;
    color: white;
 }

.style-input .style-input-space-blocker {
    line-height: 3vh;
    padding-left: 5.4vw; 
    padding-right: 5.4vw;
    padding-top: 4vh;
    padding-bottom: 1vh;
    /* margin: 5px; */
    display: inline-block;
    width: 29vw;
}

.style-input.style-input-larger .style-input-space-blocker {
    width: 70vw;
}

.style-input.style-input-dark .style-input-background {
    background-color: #191e32;
    color: #4d5380;
}

.style-input.style-input-light .style-input-background {
    background-color: #4d5380;
    color: #191e32;
}
